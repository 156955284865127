import { useAddLojaNovoPedidoLoja } from 'domains/loja/loja-novo-pedido-loja'
import { useAddProposalQuotationCalculateDeliveryValue } from 'domains/proposal/proposal-quotation-calculate-delivery-value'
import { useAddProposalQuotationChangeDeliveryValue } from 'domains/proposal/proposal-quotation-change-delivery-value'
import { formatReal } from 'utils/format'
import { useToast } from '@77sol/core'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useState } from 'react'
import { INITIAL } from 'containers/CheckoutModal/constants'
import { useByPassSaveProposal } from 'layouts/Dashboard/hooks/useByPassSaveProposal'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

interface Props {
  methods: any
  quotationId: number
  setIsAlterDeliveryModalOpen: (value: boolean) => void
  quotation: any
  setIsSuccessModalOpen: (value: boolean) => void
}

export const useShipmentForm = ({
  methods,
  quotationId,
  setIsAlterDeliveryModalOpen,
  quotation,
  setIsSuccessModalOpen,
}: Props) => {
  const { logEvent } = useAmplitude()
  const { setOrderId } = useCheckoutModalStore()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false)
  const [deliveryValue, setAlterDelivery] = useState(INITIAL.ALTER_DELIVERY)

  const addProposalQuotationChangeDeliveryValue =
    useAddProposalQuotationChangeDeliveryValue()
  const addProposalQuotationCalculateDeliveryValue =
    useAddProposalQuotationCalculateDeliveryValue()
  const addLojaNovoPedidoLoja = useAddLojaNovoPedidoLoja()

  const continueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.continueQuotationWithChangesCallback,
  )

  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )

  const setContinueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.setContinueQuotationWithChangesCallback,
  )

  const validateDeliveryValue = async () => {
    return await addProposalQuotationCalculateDeliveryValue
      .mutateAsync({
        quotation_id: quotationId,
        cep: methods.getValues('deliveryAddress.cep'),
        city: methods.getValues('deliveryAddress.cidade'),
        state: methods.getValues('deliveryAddress.estado'),
      })
      .then(({ after, before }) => {
        const values = {
          old: formatReal(before.equipment_value),
          new: formatReal(after.equipment_value),
        }

        if (values.old !== values.new) {
          setAlterDelivery({
            old_value: values.old,
            new_value: values.new,
          })
          setIsAlterDeliveryModalOpen(true)

          return false
        }

        const { subtotal, discount, delivery, equipment_value } = after
        return {
          total: equipment_value,
          subtotal,
          discount,
          delivery,
        }
      })
  }

  const acceptNewDeliveryValue = async () => {
    setIsAlterDeliveryModalOpen(false)

    await addProposalQuotationChangeDeliveryValue
      .mutateAsync({
        quotation_id: quotationId,
        cep: methods.getValues('deliveryAddress.cep'),
        city: methods.getValues('deliveryAddress.cidade'),
        state: methods.getValues('deliveryAddress.estado'),
      })
      .then(() => {
        handleCreateOrder()
      })
  }

  const cancelNewDeliveryValue = () => {
    setIsAlterDeliveryModalOpen(false)
    setIsLoadingCreateOrder(false)
  }

  const { handleByPassSaveProposal } = useByPassSaveProposal()

  const handleCreateOrder = () => {
    logEvent(ordersTracker.actions.createOrder)
    setIsLoadingCreateOrder(true)
    validateDeliveryValue()
      .then(async (data: any) => {
        if (data) {
          await addLojaNovoPedidoLoja.mutateAsync(
            {
              billingAddress: {
                ...methods.getValues('billingAddress'),
              },
              deliveryAddress: {
                ...methods.getValues('deliveryAddress'),
              },
              order: {
                total: data.total,
                subtotal: data.subtotal,
                desconto: data.discount,
                frete_valor: data.delivery || quotation.delivery_value,
                quotation_id: quotationId,
              },
              confirm_quotation_changes:
                continueQuotationWithChangesCallback !== null,
            },
            {
              onSuccess: (data) => {
                setIsSuccessModalOpen(true)
                onSuccessOpenToast('Reserva realizada com sucesso!')
                handleByPassSaveProposal(true)
                setOrderId(data?.order_id)
                setContinueQuotationWithChangesCallback(null)
                setOpenModalContinueWithChanged(false)
              },
              onSettled: () => {
                setIsLoadingCreateOrder(false)
              },
            },
          )
        }
      })
      .catch(() =>
        onErrorOpenToast(
          'Ocorreu um erro, não foi possível finalizar o seu pedido, tente novamente mais tarde.',
        ),
      )
  }

  return {
    acceptNewDeliveryValue,
    handleCreateOrder,
    isLoadingCreateOrder,
    deliveryValue,
    addLojaNovoPedidoLoja,
    addProposalQuotationChangeDeliveryValue,
    cancelNewDeliveryValue,
  }
}

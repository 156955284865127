import { type Resolver, useForm, useFormContext } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerValueFormData,
} from '../types'
import { roleAdminSchema, baseSchema } from '../validators/formValidator'
import { useIsAdmin } from 'hooks/useIsAdmin'

export function useCreateFinancingPerValueForm(
  defaultValues: DefaultValuesForm,
) {
  const isAdmin = useIsAdmin()

  const schema = useMemo(
    () => (isAdmin ? roleAdminSchema : baseSchema),
    [isAdmin],
  )

  const methods = useForm<ICreateFinancingPerValueFormData>({
    resolver: yupResolver(schema) as Resolver<ICreateFinancingPerValueFormData>,
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  return methods
}

export const useCreateFinancingPerValueFormContext = () => {
  return useFormContext<ICreateFinancingPerValueFormData>()
}

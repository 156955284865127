import { Form } from './styles'
import { useCreateFinancingPerValueFormContext } from './hooks/useCreateFinancingPerValueForm'
import { type ICreateFinancingPerValueFormProps } from './types'
import { FormPart } from './components/FormPart'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { useEffect } from 'react'

export function FormManager({
  formId,
  onSubmit,
}: ICreateFinancingPerValueFormProps) {
  const { handleSubmit, getValues, clearErrors, trigger } =
    useCreateFinancingPerValueFormContext()

  const choosedPersonType = getValues('client_type')
  const clientDocument = getValues('client_document')

  useEffect(() => {
    clearErrors('client_name')
    clearErrors('client_birth_date')
    clearErrors('client_income')
    if (clientDocument) trigger('client_document')
  }, [choosedPersonType, clearErrors, trigger, clientDocument])

  return (
    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormPart.Commom />
      {choosedPersonType === PERSON_TYPE_ENUM.NATURAL && (
        <FormPart.NaturalPerson />
      )}
      {choosedPersonType === PERSON_TYPE_ENUM.JURIDICAL && (
        <FormPart.JuridicalPerson />
      )}
    </Form>
  )
}

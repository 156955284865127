import DashboardDefault from 'views/DashboardDefault'
import { Home } from '..'
import { usePartialDelivery } from './hooks/usePartialDelivery'

export function PartialDelivery() {
  const { showNewHomeScreen } = usePartialDelivery()

  if (showNewHomeScreen) {
    return <Home />
  }

  return <DashboardDefault />
}

import { type IChartData } from '@77sol-ui/molecules'

const lineStyles: Record<
  'vendas' | 'financiamentos',
  Omit<IChartData, 'data' | 'yAxisID'>
> = {
  vendas: {
    label: 'Vendas',
    lineColor: '#2D69F6',
    backgroundColor: '#2D69F6',
    fillColor: '#2D69F6',
    lineWidth: 3,
  },
  financiamentos: {
    label: 'Financiamentos',
    lineColor: '#F2994A',
    backgroundColor: '#F2994A',
    fillColor: '#F2994A',
    lineWidth: 3,
  },
}

export { lineStyles }

import { useMemo } from 'react'
import { useGetConversionData } from 'domains/home/dashboard-analysis-conversion/hooks/useGetConversionData'
import { Chart } from '@77sol-ui/molecules'
import { useToast } from '@77sol/core'
import { lineStyles } from './constants'
import { ChartContainer, Wrapper } from './styles'
import { type IFilters } from 'views/Home/components/Performance/types'

import { formatDataToChartJs } from '../../utils/formatDataToChartJs'

import { CircularProgress } from 'components'
import { useCustomizeLabels } from './hooks/useCustomizeLabels'
import { Legend } from '../Legend'
import useWindowSize from 'hooks/useWindowSize'

export interface IConversionChartProps {
  filters: Omit<IFilters, 'chart'>
}

export function ConversionChart({ filters }: IConversionChartProps) {
  const { width } = useWindowSize()
  const isMobile = width <= 768
  const { onErrorOpenToast } = useToast()

  const { data, isLoading } = useGetConversionData({
    params: filters,
    onError: () => {
      onErrorOpenToast('Erro ao buscar os dados de conversão')
    },
  })

  const formattedData = useMemo(() => {
    return data && !isLoading && formatDataToChartJs({ lineStyles, data })
  }, [data, isLoading])

  const {
    customizeMoneyAxisLabel,
    customizeDataAxisLabel,
    customizeTooltipTitle,
    customizeTooltipLabel,
  } = useCustomizeLabels({
    period: filters.period,
    data,
    units: formattedData?.units,
  })

  if (isLoading) {
    return (
      <ChartContainer>
        <CircularProgress text="Carregando..." />
      </ChartContainer>
    )
  }

  return (
    <Wrapper>
      <div>
        <Legend
          text="Vendas"
          color="blue"
          maxWidth="175px"
          description="Valor em reais (R$) de projetos e kits pagos."
        />
        <Legend
          text="Financiamentos"
          color="orange"
          maxWidth="220px"
          description="Valor em reais (R$) de financiamentos aprovados."
        />
      </div>

      <ChartContainer>
        <Chart.Line
          data={formattedData?.data || []}
          labels={formattedData?.labels || []}
          lineRadius={0.3}
          plugins={[Chart.Plugins.crosshair, Chart.Plugins.legendSpacing]}
          maintainAspectRatio={false}
          showLegends={false}
          showDotsInTooltip
          maxTicksLimit={7}
          customizeYTicks={customizeMoneyAxisLabel}
          customizeXTicks={customizeDataAxisLabel}
          customizeTooltip={{
            title: customizeTooltipTitle,
            label: customizeTooltipLabel,
          }}
          tiltLabels={isMobile}
        />
      </ChartContainer>
    </Wrapper>
  )
}

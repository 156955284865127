import React, { useState, useEffect } from 'react'
import { Box, Hidden, Grid } from '@material-ui/core'
import { Page, Loading } from 'components'

import { TabList, TabContent, Dropdown, Spacer, useToast } from '@77sol/core'
import { useSelector } from 'react-redux'
import API from 'api'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { ProModal } from 'containers/Modals'
import { Header } from 'components/NewHeader'
import { Geral, Infografico, Gestao } from './Tabs'
import styles from './styles'
import { monthList, yearsList, kanbanInitialState } from './utils'
import bannerImage from '../../assets/img/77sol_selection_banner.png'
import bannerImageTop from '../../assets/img/77sol_selection_banner_top.png'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { SolLeadAlert } from 'containers/SolLeadAlert'

function DashboardDefault() {
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)
  const isUserProPlan = useSelector((state) => state.ReducerProfile.isProPlan)
  const showForPayment = useSelector((state) => state.Reducer1.showForPayment)
  const planInfos = useSelector((state) => state.ReducerProfile.planInfos)
  const userProfile = useSelector((state) => state.ReducerProfile.userProfile)

  const { setIsQuotationModalOpen } = useQuotationModalContext()

  const { onDefaultOpenToast } = useToast()

  const [mesAtualFiltro, setMesAtualFiltro] = useState(
    new Date().getMonth() + 1,
  )
  const [anoAtualFiltro, setAnoAtualFiltro] = useState(new Date().getFullYear())
  const [shouldRequest, setShouldRequest] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [financeiroValues, setFinanceiroValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [kanbanList, setKanbanList] = useState([])
  const [isKanbanLoading, setIsKanbanLoading] = useState(false)
  const [hasDoneFirstLoading, setHasDonesFirstLoading] = useState(false)
  const [updateProposals, setUpdateProposals] = useState([])
  const [pageUpdateProposals, setPageUpdateProposals] = useState(1)
  const [hasMoreUpdateProposals, setHasMoreUpdateProposals] = useState(false)
  const [isUpdatesProposalsLoading, setIsUpdatesProposalsLoading] =
    useState(false)

  const [isRegisteredUser, setIsRegisteredUser] = useState(false)

  const [isProPlanModalOpen, setIsProPlanModalOpen] = useState(false)

  const classes = styles()

  const [showForPaymentModal, setShowForPaymentModal] = useState(false)

  useEffect(() => {
    setShowForPaymentModal(showForPayment)
  }, [showForPayment])

  useEffect(() => {
    if (userLogged[0]?.integrador_id) {
      setIsRegisteredUser(true)
    }
    if (window.location.href.includes('quotationModal')) {
      setIsQuotationModalOpen(true)
    }
  }, [])

  const getValues = async () => {
    if (!shouldRequest) {
      setIsLoading(true)
    }
    try {
      const { data } = await API.get('/home/dashboard', {
        params: {
          month: mesAtualFiltro,
          year: anoAtualFiltro,
        },
      })

      setFinanceiroValues(data)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
      setHasDonesFirstLoading(true)
      setShouldRequest(false)
    }
  }

  useEffect(() => {
    getValues()
  }, [mesAtualFiltro, anoAtualFiltro])

  useEffect(() => {
    if (shouldRequest) {
      getValues()
    }
  }, [shouldRequest])

  useEffect(() => {
    if ((isLoading && hasDoneFirstLoading && !shouldRequest) || isKanbanLoading)
      onDefaultOpenToast('Atualizando dados')
  }, [isKanbanLoading, isLoading, hasDoneFirstLoading, shouldRequest])

  useEffect(() => {
    const getProposals = async () => {
      setIsKanbanLoading(true)

      try {
        const proposalsList = Promise.all(
          kanbanInitialState.map(async (list) => {
            const {
              data: {
                proposals: { current_page, last_page, data, total },
              },
            } = await API.get(`/proposal/by-status/${list.id}`, {
              params: {
                month: mesAtualFiltro,
                year: anoAtualFiltro,
              },
            })

            return {
              ...list,
              hasMore: current_page < last_page,
              month: mesAtualFiltro,
              year: anoAtualFiltro,
              page: 1,
              totalItems: total,
              items: data,
            }
          }),
        )

        setKanbanList(await proposalsList)
      } catch (e) {
        console.log(e)
      } finally {
        setIsKanbanLoading(false)
      }
    }

    getProposals()
  }, [mesAtualFiltro, anoAtualFiltro])

  useEffect(() => {
    const getProposals = async () => {
      if (pageUpdateProposals === 1) {
        setIsUpdatesProposalsLoading(true)
      }

      try {
        const {
          data: { data = [], last_page },
        } = await API.get('/proposal/logs', {
          params: { page: pageUpdateProposals },
        })

        setUpdateProposals((prevState) => [...prevState, ...data])
        setHasMoreUpdateProposals(last_page > pageUpdateProposals)
      } catch (e) {
        console.log(e)
      } finally {
        setIsUpdatesProposalsLoading(false)
      }
    }

    getProposals()
  }, [pageUpdateProposals])

  const handleGetProposalsLog = () => {
    if (hasMoreUpdateProposals) {
      setPageUpdateProposals(pageUpdateProposals + 1)
    }
  }

  const [isSelection77Campaign, setIsSelection77Campaign] = useState(false)
  useEffect(() => {
    if (userProfile?.tags) {
      userProfile.tags.forEach((tag) => {
        if (tag.slug == 'selecao77') {
          setIsSelection77Campaign(true)
        }
      })
    }
  }, [userProfile])

  return (
    <Page
      className={classes.root}
      title={currentEnterpriseConfigs.titlePage('Dashboard')}
    >
      <Header style={{ marginBottom: '20px' }} />

      {isSelection77Campaign && planInfos?.pago <= 0 && (
        <>
          <Box
            px={4}
            onClick={() => {
              setShowForPaymentModal(false)
              setIsProPlanModalOpen(true)
            }}
          >
            <img
              alt="Seleção 77Sol"
              src={bannerImageTop}
              style={{
                width: '100%',
                borderRadius: '15px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </>
      )}
      <Box px={4}>
        <SolLeadAlert />
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          marginTop="20px"
        >
          <Box flex={2}>
            <TabList
              titles={['Geral', 'Gestão', 'Infográfico']}
              onChange={(e) => {
                if (e == 3) {
                  if (isUserProPlan) {
                    setSelectedTab(e)
                  } else {
                    setIsProPlanModalOpen(true)
                  }
                } else {
                  setSelectedTab(e)
                }
              }}
              value={selectedTab}
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <Hidden mdUp>
              <Spacer size="16" direction="both" />
            </Hidden>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Dropdown
                  title="Mês"
                  fullWidth
                  onChange={setMesAtualFiltro}
                  initialValue={mesAtualFiltro}
                  options={monthList}
                />
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  title="Ano"
                  fullWidth
                  initialValue={anoAtualFiltro}
                  onChange={setAnoAtualFiltro}
                  options={yearsList}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <TabContent value={selectedTab} index={0}>
          <Loading
            isLoading={isLoading && !hasDoneFirstLoading}
            minHeight="250px"
          >
            <Geral
              values={financeiroValues.general}
              updateProposals={updateProposals}
              handleGetProposalsLog={handleGetProposalsLog}
              isUpdatesProposalsLoading={isUpdatesProposalsLoading}
              hasMoreUpdateProposals={hasMoreUpdateProposals}
              isRegisteredUser={isRegisteredUser}
            />
          </Loading>
        </TabContent>

        <TabContent value={selectedTab} index={1}>
          <Loading isLoading={isKanbanLoading} minHeight="250px">
            <Gestao
              kanbanList={kanbanList}
              month={mesAtualFiltro}
              year={anoAtualFiltro}
              setShouldRequest={setShouldRequest}
            />
          </Loading>
        </TabContent>

        <TabContent value={selectedTab} index={2}>
          <Infografico values={financeiroValues.infographic} />
        </TabContent>
      </Box>
      {isProPlanModalOpen && (
        <ProModal
          isOpen={isProPlanModalOpen}
          onClose={() => setIsProPlanModalOpen(false)}
          showForPayment={showForPaymentModal}
        />
      )}
      {isSelection77Campaign && planInfos?.pago <= 0 && (
        <>
          <Box
            px={4}
            onClick={() => {
              setShowForPaymentModal(false)
              setIsProPlanModalOpen(true)
            }}
            style={{ marginBottom: '20px' }}
          >
            <img
              alt="Seleção 77Sol"
              src={bannerImage}
              style={{
                width: '100%',
                borderRadius: '15px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </>
      )}
    </Page>
  )
}

export default DashboardDefault

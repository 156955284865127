import React, { useState } from 'react'
import { Grid, Avatar, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Button, Paper, Spacer, useToast } from '@77sol/core'
import { useSelector, useDispatch } from 'react-redux'
import { DeleteIcon, ExchangeTransferIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { addWorkDays, handlePotency } from 'utils'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import {
  ModalAlterarProduto,
  ModalDescricao,
} from '../../../StepTwo/components'
import { ProductRemoveModal } from 'containers/Modals/ProductRemoveModal'
import { useRemoveProduct } from 'views/DimensionarV2/hooks/useRemoveProduct'

function EquipmentsList({
  quoteEdit,
  isFromProposalList,
  onlyVerticalPadding,
}) {
  const dispatch = useDispatch()

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const quotationId = quoteEdit.quotation.id
  const ActiveQuote = useSelector((state) =>
    state.Reducer1.quotes.find((quote) => quote.quotation.id == quotationId),
  )

  const [modalDescricao, setModalDescricao] = useState(false)
  const [modalAlterar, setModalAlterar] = useState(false)
  const [datasheetProduct, setDatasheetProduct] = useState({})
  const [productToChange, setProductToChange] = useState({})

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { productToDelete, handleOpenDeleteProduct, handleCloseDeleteProduct } =
    useRemoveProduct()

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  const handleChangeProduct = async (
    newProduct,
    currentProduct,
    quote,
    qtde,
    newQtde,
  ) => {
    await API.post('/proposal/quotation/item/change ', {
      quotation_id: quote.quotation.id,
      new_product_id: newProduct.id,
      product_qty: qtde,
      new_product_qty: newQtde || null,
      potency: handlePotency(
        quote.quotation.added_items
          ? filterAddedItems(quote.kit, quote.quotation.added_items)
          : quote.kit,
      ),
      current_product_id: currentProduct.id,
    })
      .then((res) => {
        setModalAlterar(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        onSuccessOpenToast('Produto alterado com sucesso')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o produto')
      })
  }

  const handleOrderProduct = (product) => {
    const newArray = [...product]
    newArray.sort((a, b) => {
      if (
        a.categoria == 62 ||
        a.categoria == 61 ||
        a.categoria == 66 ||
        a.categoria == 63
      ) {
        if (a.categoria == 62) {
          return -1
        }
        if (b.categoria == 62) {
          return 1
        }
        if (a.categoria == 61) {
          return -1
        }
        if (b.categoria == 61) {
          return 1
        }
        if (a.categoria == 66) {
          return -1
        }
        if (b.categoria == 66) {
          return 1
        }
        if (a.categoria == 63) {
          return -1
        }
        if (b.categoria == 63) {
          return 1
        }
      } else {
        return 1
      }
    })
    return newArray
  }

  return (
    <div style={{ height: 'fit-content' }}>
      {handleOrderProduct(ActiveQuote.kit).map((product, i) => (
        <Paper
          key={product.titulo + product.qtde + i}
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            borderRadius: i == 0 ? '8px 8px 0px 0px' : '0px',
            borderBottom: `2px solid ${palette.grayscale[300]}`,
            backgroundColor: palette.white,
            padding: onlyVerticalPadding ? '24px 8px' : '24px',
          }}
        >
          <Grid container spacing={2}>
            {!downLg && !isFromProposalList && (
              <Grid item xs={12} md={3} xl={2}>
                <Avatar
                  src={product.url}
                  variant="rounded"
                  style={{ width: '64px', height: '64px', borderRadius: '8px' }}
                />
              </Grid>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingBottom: !upXl && '0px',
              }}
              item
              xs={9}
              md={9}
              lg={9}
              xl={8}
            >
              <Typography type="text_small" color="grayscale" colorWeight="800">
                {product.titulo?.split(' - Disp. em estoque:')[0]}
              </Typography>
              {upXl && (
                <>
                  <Typography
                    type="text_x_small"
                    color="grayscale"
                    colorWeight="400"
                  >
                    {product.titulo?.split(' - Disp. em estoque:')[1]
                      ? `Disp. em estoque:${
                          product.titulo?.split(' - Disp. em estoque:')[1]
                        }`
                      : 'Disponível em estoque'}
                  </Typography>
                  {product.garantia ? (
                    <Typography
                      type={downSm ? 'text_small' : 'link_medium'}
                      color="green"
                      colorWeight="300"
                    >
                      {`${product.garantia} anos de garantia`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'flex-end',
                paddingBottom: !upXl && '0px',
              }}
              item
              xs={3}
              md={3}
              lg={3}
              xl={2}
            >
              <Typography type="text_large" color="grayscale" colorWeight="800">
                {product.qtde}
              </Typography>
              <Spacer size="4" direction="vertical" />
              <Typography
                type="text_x_small"
                color="grayscale"
                colorWeight="800"
              >
                un
              </Typography>
            </Grid>
            {!upXl && (
              <Grid style={{ paddingTop: '0px' }} item xs={12}>
                <Typography
                  type="text_x_small"
                  color="grayscale"
                  colorWeight="400"
                >
                  {product.titulo?.split(' - Disp. em estoque:')[1]
                    ? `Disp. em estoque:${
                        product.titulo?.split(' - Disp. em estoque:')[1]
                      }`
                    : 'Disponível em estoque'}
                </Typography>
                {product.garantia && (
                  <Typography
                    type={downSm ? 'text_small' : 'link_medium'}
                    color="green"
                    colorWeight="300"
                  >
                    {`${product.garantia} anos de garantia`}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={isFromProposalList ? 12 : 4}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                fullWidth={!downSm}
                size="small"
                noMargin
                variant="outlined"
                onClick={() => {
                  setDatasheetProduct(product)
                  setModalDescricao(true)
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: 'max-content',
                }}
              >
                Dados técnicos
              </Button>
              {(!upXl || isFromProposalList) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  {/* {(product.categoria == 61 ||
                    product.categoria == 63 ||
                    product.categoria == 62 ||
                    product.categoria == 66) && (
                    <ExchangeTransferIcon
                      width="24"
                      color={palette.grayscale[800]}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setProductToChange(product)
                        setModalAlterar(true)
                      }}
                    />
                  )} */}
                  <Spacer size="24" direction="vertical" />
                  <DeleteIcon
                    width="24"
                    color={palette.red[300]}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleOpenDeleteProduct(product)
                    }}
                  />
                </div>
              )}
            </Grid>
            {!downLg && !isFromProposalList && (
              <>
                {(product.categoria == 61 ||
                  product.categoria == 63 ||
                  product.categoria == 62 ||
                  product.categoria == 66) && (
                  <Grid item xs={12} md={12} lg={12} xl={4}>
                    <Button
                      fullWidth
                      size="small"
                      noMargin
                      variant="outlined"
                      onClick={() => {
                        setProductToChange(product)
                        setModalAlterar(true)
                      }}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Alterar item
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} xl={4}>
                  <Button
                    fullWidth
                    size="small"
                    noMargin
                    variant="outlined"
                    color="red"
                    onClick={() => {
                      handleOpenDeleteProduct(product)
                    }}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    Excluir
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      ))}
      <Spacer size="48" direction="horizontal" />
      {productToDelete && (
        <ProductRemoveModal
          product={productToDelete}
          quotationId={quotationId}
          onClose={handleCloseDeleteProduct}
          onSuccess={handleCloseDeleteProduct}
        />
      )}
      {modalAlterar && (
        <ModalAlterarProduto
          isOpen={modalAlterar}
          product={productToChange}
          quotationId={quoteEdit?.quotation?.id}
          onChangeProduct={handleChangeProduct}
          onClose={() => setModalAlterar(false)}
        />
      )}
      <ModalDescricao
        addWorkDays={addWorkDays}
        datasheetProduct={datasheetProduct}
        modalDescricao={modalDescricao}
        setModalDescricao={setModalDescricao}
      />
    </div>
  )
}

export default EquipmentsList

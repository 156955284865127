import {
  ChartFiltersValueEnum,
  PeriodsFiltersValueEnum,
} from '../components/Filters/constants'

export const DEFAULT_FILTERS_STATE = {
  period: PeriodsFiltersValueEnum.DIA,
  interval: 7,
  chart: ChartFiltersValueEnum.CONVERSAO,
}

export const CONVERSION_CHART_SUBTITLE =
  'Acompanhe as oportunidades que surgiram para o seu negócio e que ainda podem ser convertidas.'
export const ORIGINATION_CHART_SUBTITLE =
  'Acompanhe o crescimento do seu negócio, em vendas de projetos e de financiamentos.'
export const SUSTAINABILITY_CHART_SUBTITLE =
  'Acompanhe o impacto positivo dos projetos na redução das emissões de CO2.'

export const CHART_SUBTITLE = {
  conversion: CONVERSION_CHART_SUBTITLE,
  origination: ORIGINATION_CHART_SUBTITLE,
  sustainability: SUSTAINABILITY_CHART_SUBTITLE,
}

import { Carousel as Base } from '@77sol-ui/molecules'
import BannerMobile from 'assets/carousel/banner-mobile.png'
import BannerDesktop from 'assets/carousel/banner.png'
import { CAROUSEL_DEFAULT_CONFIGS } from './constants'
import useWindowSize from 'hooks/useWindowSize'
import { useSidebarDesktopStore } from 'store/modals/SidebarStore'

export function Carousel() {
  const { width } = useWindowSize()
  const isLargeScreen = width >= 1680
  const isDesktop = width >= 1280
  const { isOpen } = useSidebarDesktopStore()

  const centerPaddingDesktop = isOpen ? '35px' : '120px'
  const centerPaddingBanner = isDesktop ? centerPaddingDesktop : '20px'

  const carouselConfigs = {
    ...CAROUSEL_DEFAULT_CONFIGS,
    dots: isDesktop,
    slidesToShow: isLargeScreen ? 2 : 1,
    centerPadding: centerPaddingBanner,
  }

  return (
    <Base.Root {...carouselConfigs}>
      <Base.Item>
        <Base.Image mobileSrc={BannerMobile} src={BannerDesktop} />
      </Base.Item>
      <Base.Item>
        <Base.Image mobileSrc={BannerMobile} src={BannerDesktop} />
      </Base.Item>
      <Base.Item>
        <Base.Image mobileSrc={BannerMobile} src={BannerDesktop} />
      </Base.Item>
    </Base.Root>
  )
}

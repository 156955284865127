import { useAcl } from 'acl/hooks/useAcl'

export function usePartialDelivery() {
  const { user } = useAcl()

  const isInternalUser = user[0]?.email.endsWith('@77sol.com.br')

  const showNewHomeScreen = isInternalUser

  return { showNewHomeScreen }
}

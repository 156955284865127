import { useEffect } from 'react'
import { Header } from 'components/NewHeader'
import { Page, Carousel } from 'components'
import { SolLeadAlert } from 'containers/SolLeadAlert'
import { SummaryRankClassification } from 'containers'
import { Performance } from './components/Performance'
import { FinancingAndProposalsTable } from './components/FinancingAndProposalsTable'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import * as S from './styles'

export function Home() {
  const { setIsQuotationModalOpen } = useQuotationModalContext()

  useEffect(() => {
    if (window.location.href.includes('quotationModal')) {
      setIsQuotationModalOpen(true)
    }
  }, [setIsQuotationModalOpen])

  return (
    <Page title="Home">
      <Header />
      <S.CarouselWrapper>
        <Carousel />
      </S.CarouselWrapper>
      <S.Container>
        <SolLeadAlert />
        <FinancingAndProposalsTable />
        <SummaryRankClassification />
        <Performance />
      </S.Container>
    </Page>
  )
}
